export default [
  {
    label: '阿里云图标',
    list: [
      'icon-quanxianguanli',
      'icon-yonghuguanli',
      'icon-jiaoseguanli',
      'icon-web-icon-',
      'icon-xitongguanli',
      'icon-rizhiguanli',
      'icon-navicon-zdgl',
      'icon-weibiaoti46',
      'icon-miyue',
      'icon-shouji',
      'icon-miyue',
      'icon-denglvlingpai',
      'icon-luyou',
      'icon-msnui-supervise',
      'icon-server',
      'icon-wendang',
      'icon-gtsquanjushiwufuwuGTS',
      'icon-caidanguanli',
      'icon-guanwang',
      'icon-guanwangfangwen',
      'icon-guiji',
      'icon-fensiguanli',
      'icon-gongzhonghao',
      'icon-anniu_weixincaidanlianjie',
      'icon-weixincaidan',
      'icon-xiaoxiguanli',
      'icon-zhexiantu',
      'icon-canshu',
      'icon-erji-zuhushouye',
      'icon-pay6zhifu',
      'icon-zhifuqudaoguanli',
      'icon-dingdan',
      'icon-tuikuan',
      'icon-webicon14',
      'icon-shouyintai',
      'icon-wenjianguanli',
      'icon-mysql',
      'icon-shejiyukaifa-',
      'icon-record',
      'icon-biaodanbiaoqian',
      'icon-TagAdd',
      'icon-sucaisads',
      'icon-huifu',
      'icon-bangzhushouji',

    ]
  }
]
