<template>
  <basic-container>
    <div class="avue-crud">
      <el-form :inline="true">
        <el-form-item>
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="addOrUpdateHandle(false, null)"
          >
            添加品种
          </el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <!-- <el-col :xs="24" :sm="24" :md="5" style="margin-top: 15px">
          <el-tree
            :data="treeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            class="filter-tree"
            node-key="id"
            highlight-current
            @node-click="getNodeData"
          />
        </el-col> -->
        <el-col :xs="24" :sm="24" :md="19" style="margin-top: 15px ; width:100%;">
          <el-table
            border
            v-loading="loading"
            :data="menuList"
            row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildrens' }"
          >
            <el-table-column
              prop="categoryName"
              label="品种名称"
              :show-overflow-tooltip="true"
              width="100%"
            ></el-table-column>
            <el-table-column
              prop="type"
              label="类型"
              width="200"
              align="center"
            >
              <template slot-scope="scope">
                <el-tag type="info" v-if="scope.row.type == 1">大类</el-tag>
                <el-tag type="success" v-if="scope.row.type == 2"
                  >子品种</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="areaCode"
              label="行政区编号"
              :show-overflow-tooltip="true"
              align="center"
            >
            </el-table-column>

            <el-table-column
              label="操作"
              align="center"
              class-name="small-padding fixed-width"
            >
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="text"
                  icon="el-icon-add"
                  @click="addOrUpdateHandle(false, scope.row)"
                  >添加
                </el-button>
                <el-button
                  size="small"
                  type="text"
                  icon="el-icon-edit"
                  @click="addOrUpdateHandle(true, scope.row)"
                  >修改
                </el-button>
                <el-button
                  size="small"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table></el-col
        >
      </el-row>

      <table-form
        v-show="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getList"
      ></table-form>
    </div>
  </basic-container>
</template>

<script>
import { split } from "@/util/utils.js";
import { mapGetters } from "vuex";
import {
  getTreeList,
  deleteTreeById,
  getRegionToCity,
} from "@/api/CollectivePurchase";
import TableForm from "./component/menu-form";

export default {
  name: "Menu",
  components: { TableForm },
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: "children",
        label: "areaName",
      },
      addOrUpdateVisible: false,
      // 遮罩层
      loading: true,
      // 菜单表格树数据
      menuList: [],
      // 菜单树选项
      menuOptions: [],
      areaCode: null,
    };
  },
  created() {
    this.getList();
    this.initRegion();
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  methods: {
    addOrUpdateHandle(isEdit, obj) {

      // 点击修改数据
      this.addOrUpdateVisible = true;
      // this.$nextTick(() => {
      this.$refs.addOrUpdate.init(isEdit, obj);
      // })
    },
    getList() {
      // 获取列表数据
      this.loading = true;

      // console.log(this.areaCode,'查看areaCode')

      // if( this.areaCode.length==0){
      //   this.areaCode=this.identityObj.areaCode
      // }
      this.areaCode=this.identityObj.areaCode
      console.log(this.areaCode)
      getTreeList({ areaCode: this.areaCode }).then((response) => {
        this.menuList = response.data;
        this.loading = false;
      });
    },
    handleDelete(row) {
      // 点击删除
      this.$confirm("是否确认删除【 " + row.categoryName + " 】?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(function () {
          return deleteTreeById(row.id);
        })
        .then(() => {
          this.getList();
          this.$message.success("删除成功");
        });
    },
    async initRegion() {
      // 获取行政区树
      let res = await getRegionToCity({ level: "3" });
      let { code, data } = res;
      if (code == 0) {
        let temp = data;
        this.treeData = split(temp);
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    async getNodeData(row) {
      // 品种数子元素点击事件
      this.areaCode = row.id;
      this.getList();
      this.$refs.addOrUpdate.form.areaCode = row.id;
    },
  },
};
</script>
