<template>
  <!-- 添加或修改菜单对话框 -->
  <el-dialog
    :title="!editFlag ? '新增' : '修改'"
    :visible.sync="visible"
    width="500px"
  >
    <el-form ref="dataForm" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="上级菜单">
        <treeselect
          v-model="form.parentId"
          :options="menuOptions"
          :normalizer="normalizer"
          :show-count="true"
          placeholder="选择上级菜单"
        />
      </el-form-item>
      <el-form-item :label="label" prop="categoryName">
        <el-input v-model="form.categoryName" :placeholder="'请输入' + label" />
      </el-form-item>
      <!-- <el-form-item label="所在区域" v-if="showArea">
        <el-cascader
          v-model="form.areaCode"
          :props="props"
          :show-all-levels="false"
          :options="regionData"
          placeholder="请选择所在区域"
          clearable
        ></el-cascader>
      </el-form-item> -->

      <el-form-item label="备注" prop="remarks" v-if="!showArea">
        <el-input v-model="form.remarks" style="width: 80%" type="textarea" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dataFormSubmit">确 定</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getTreeList,
  filterTree,
  addTreeObj,
  getRegionToCity,
} from "@/api/CollectivePurchase";
import { mapGetters } from "vuex";
import { split } from "@/util/utils.js";
import Treeselect from "@riophae/vue-treeselect";
import iconList from "../iconList";
import TableForm from "./menu-form";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "Menu",
  components: { Treeselect, TableForm },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 菜单树选项
      menuOptions: [],
      // 是否显示弹出层
      visible: false,
      // 图标
      iconList: iconList,
      editForm: {},
      editFlag: false,
      showArea: false,
      label: "地块类型",
      form: {
        areaCode: "",
        id: "",
        parentId: "-1",
        categoryName: "",
        categoryCode: "",
        // maxPrice: null,
        // minPrice: null,
        type: "1",
        averagePrice: 0,
        remarks: "",
      },
      // 表单校验
      rules: {
        categoryName: [
          { required: true, message: "品种名称不能为空", trigger: "blur" },
        ],
        // minPrice: [{ required: true, message: '最低价不能为空', trigger: 'blur' }],
        // maxPrice: [{ required: true, message: '最高价不能为空', trigger: 'blur' }],
      },
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      regionData: [],
    };
  },
  watch: {
    visible: function () {
      if (!this.visible) {
        this.showArea = false;
      }
    },
  },
  created() {
    this.initRegion();
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },
  methods: {
    init(isEdit, obj) {
      // 初始化表单数据
      if (!obj) {
        //如果没有值
        this.form.parentId = -1;
        this.showArea = true;
        this.label = "地块类型";
        this.form.type = "1";
      } else {
        this.label = "品种类型";
        this.form.type = "2";
      }
      this.editFlag = isEdit;
      this.visible = true;
      this.getTreeselect();

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (isEdit) {
          this.form = JSON.parse(JSON.stringify(obj));
          if (obj.parentId == "-1") {
            this.showArea = true;
          }
        } else {
          this.form.id = null;
          if (obj) {
            this.$nextTick(() => {
              this.form.parentId = obj.id;
              if (obj.type == 2) {
                this.label = "环节类型";
              }
            });
          }
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.form.parentId === undefined) {
            this.form.parentId = -1;
          }
          let params = {};

          if (!this.editFlag) {
            params = {
              id: this.form.id,
              parentId: this.form.parentId,
              areaCode: this.identityObj.areaCode,
              remarks: this.form.remarks,
              categoryName: this.form.categoryName,
              type: this.form.type * 1,
            };
          } else {
            params = this.form;
            params.type = this.form.type * 1;
          }

          addTreeObj(params).then((data) => {
            console.log("data", "查看data");
            if (data.code == 0) {
              this.$message.success(data.msg);
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    /** 查询菜单下拉树结构 */
    async getTreeselect() {
      await getTreeList().then((res) => {
        let dataList=[]
        res.data.forEach(item => {
          if(item.areaCode== this.identityObj.areaCode){
            dataList.push(item)
          }
        });
        if (res.code == 0) {
          this.menuOptions = [];
          const menu = {
            id: -1,
            categoryName: "根菜单",
            children: dataList,
          };
          this.menuOptions.push(menu);
        }
      });
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.categoryName,
        children: node.children,
      };
    },
    async initRegion() {
      // 获取行政区树
      let res = await getRegionToCity();
      let { code, data } = res;
      console.log(data);
      if (code == 0) {
        let temp = data;
        this.regionData = split(temp);
      }
    },
  },
};
</script>
